<template>
  <b-sidebar
    :visible="!hideSideBar"
    bg-variant="white"
    shadow
    backdrop
    no-header
    @hidden="setSideBar(false)"
  >
    <b-navbar type="dark" variant="primary">
      <b-navbar-brand>MENU</b-navbar-brand>
    </b-navbar>
    <b-list-group flush>
      <template v-for="(link, index) in links">
        <b-list-group-item
          v-if="!link.children"
          :key="index"
          :to="link.to"
          active-class="active"
        >
          {{ link.title }}
        </b-list-group-item>

        <b-list-group-item
          v-else
          :key="index"
          :title="link.shortcut"
          active-class="active"
          v-b-toggle="`collapse-${index}`"
        >
          <div class="d-flex justify-content-between align-items-center">
            <span>
              {{ link.title }}
            </span>

            <b-icon icon="caret-down" class="transition" />
          </div>

          <b-collapse
            v-if="link.children"
            :id="`collapse-${index}`"
            class="mt-3"
            :visible="$route.path.includes(link.basePath)"
          >
            <b-list-group flush>
              <b-list-group-item
                v-for="children in link.children"
                :key="children.title"
                :to="children.to"
                id="sub-menu-side-bar"
                :active="children.to === $route.path"
              >
                <span class="ml-3">
                  {{ children.title }}
                </span>
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
        </b-list-group-item>
      </template>
    </b-list-group>
  </b-sidebar>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      links: [
        {
          title: "Dashboard",
          basePath: "dashboard",
          children: [
            {
              title: "Resumo",
              to: "/dashboard",
            },
            { title: "Relatórios", to: "/dashboard/exportacoes" },
          ],
        },
        {
          title: "Banners",
          basePath: "banners",
          children: [
            {
              title: "Gerenciar Banners",
              to: "/banners",
            },
            {
              title: "Links Personalizados",
              to: "/banners/links",
            },
          ],
        },
        {
          title: "Clientes",
          basePath: "cliente",
          children: [
            {
              title: "Lista de Clientes",
              to: "/cliente",
            },
            {
              title: "Tabela de Descontos",
              to: "/cliente/tabela-desconto",
            },
          ],
        },
        {
          title: "Financeiro",
          basePath: "financeiro",
          children: [
            {
              title: "Formas de Pagamento",
              to: "/financeiro/pagamento",
            },
          ],
        },

        {
          title: "Pedidos",
          basePath: "pedidos",
          children: [
            { title: "E-commerce", to: "/pedidos" },
            { title: "B2B", to: "/pedidosb2b" },
            {
              title: "Cancelamentos e Devoluções",
              to: "/pedidos/cancelamento",
            },
          ],
        },
        {
          title: "Produtos",
          basePath: "produtos",
          children: [
            {
              title: "Gerenciar Produtos",
              to: "/produtos",
            },
            {
              title: "Perfil dos Produtos",
              to: "/produtos/perfil",
            },
            { title: "Gerenciar Departamentos", to: "/produtos/departamento" },
            { title: "Gerenciar Catálogos", to: "/produtos/catalogo" },
            { title: "Gerenciar Grupos", to: "/produtos/grupo" },
            { title: "Caixas e Embalagens", to: "/caixa" },
          ],
        },

        {
          title: "Transportadoras",
          to: "/transportadoras",
        },
        { title: "Operações", to: "/operacoes" },
      ],
    };
  },
  computed: {
    ...mapState(["hideSideBar"]),
    ...mapGetters("auth", ["userPermission"]),

    hideLink() {
      return this.userPermission([2, 3]);
    },
  },
  methods: {
    ...mapMutations(["setSideBar"]),

    escapeListener(event) {
      if (event.ctrlKey && event.key === "m") {
        event.preventDefault();
        this.setSideBar(!this.hideSideBar);
      }
    },
  },
  created() {
    window.addEventListener("keydown", this.escapeListener);
  },
  destroyed() {
    window.removeEventListener("keydown", this.escapeListener);
  },
};
</script>

<style lang="scss" scoped>
.sidebar-wrapper {
  width: 299px;
  min-height: 100vh;
  position: fixed;
  display: block;
  transition: ease-out 0.25s;
  overflow: hidden;
  background-color: white;
}

.no-sidebar {
  width: 0px;
}

@media only screen and (max-width: 840px) {
  .sidebar-wrapper {
    z-index: 90;
    height: 100vh;
  }

  .no-sidebar {
    z-index: 0;
  }
}

.list-group-item {
  &:not(#sub-menu-side-bar) {
    &.active {
      border-left-width: 8px;
      background-color: white;
      font-weight: 600;
      color: black;
    }

    &.not-collapsed {
      > div {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.transition {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
</style>
