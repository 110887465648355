<template>
  <div>
    <slot />
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["hideSideBar"]),
  },
};
</script>

<style lang="scss" scoped>
.page-content-wrapper {
  min-height: 100vh;
  width: calc(100% - 299px);
  position: absolute;
  right: 0;
  transition: ease-out 0.25s;
}

.no-sidebar {
  width: 100%;
}

@media only screen and (max-width: 840px) {
  .page-content-wrapper {
    width: 100%;
  }
}
</style>
