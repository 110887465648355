<template>
  <b-navbar toggleable="sm" type="dark" variant="primary" fixed="top">
    <b-navbar-brand href="#" @click="setSideBar">
      <b-icon icon="layout-sidebar" />
    </b-navbar-brand>
    <span style="color: white">PAINEL - APEU</span>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-dropdown
          variant="light"
          split-variant="outline-light"
          split
          right
          class="m-2"
          style="margin: 0px !important"
        >
          <template #button-content>
            <b-icon icon="person-circle" aria-hidden="true" />
            {{ user.name }}
          </template>
          <b-dropdown-item to="/user/perfil">Perfil</b-dropdown-item>
          <b-dropdown-item @click="logOut">Sair</b-dropdown-item>
        </b-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "NavBar",
  methods: {
    ...mapMutations(["setSideBar"]),
    ...mapActions("auth", ["logout"]),

    async logOut() {
      await this.logout();
      this.$router.push("/");
    },
  },
  computed: {
    ...mapState(["hideSideBar"]),
    ...mapState("auth", ["user"]),
  },
};
</script>

<style lang="scss" scoped>
.apeu-navbar {
  z-index: 100;
  position: fixed;
  width: calc(100% - 299px);
  transition: ease-out 0.25s;
}

.no-sidebar {
  .apeu-navbar {
    width: 100%;
  }
}

@media only screen and (max-width: 840px) {
  .apeu-navbar {
    width: 100%;
  }
}
</style>
